<template>
  <b-row class="match-height">
    <b-col
      lg="10"
      md="10"
    >
   
    <b-card
      title="Import Inspection Unit">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
        <div class="d-flex align-items-center justify-content-end">
          
          <b-button
            variant="danger"
            align="right"
            @click="sampleDownload"
          >
            <span class="text-nowrap">Download Sample</span>
          </b-button>
        </div>
      </b-col>
      </b-row>
      
      <b-form @submit="formSubmit">
        
        

        <b-row>
          
          <b-col md="12">
            <b-form-group
              label="Project Site"
              
              class="required"
            >
              <b-form-select v-model="form.site" @change="updateForm()">
                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>


          <b-col md="12">
            <b-form-group
              label="Form"
              class="required"
              
            >
              <b-form-select v-model="form.form">
                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="f._id" v-for="f in forms" :key="f._id">{{f.form_name | capitalize}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>


          <b-col md="12">
            <b-form-group
              label="Upload File Template"
              
              class="required"
            >
              <b-form-file
                id="upload_file"
                ref="uploadFile"
                accept=".xls, .xlsx, .csv"
                @change="onFileChange"
                v-model="bulkFile"
              />
              <small class="text-danger">supported format: .xls or .xlsx</small>
          </b-form-group>
          </b-col>
        </b-row>
       
          <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="warning"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-col>
</b-row>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form:{
        file:'',
        site:'',
        form:'',
      },

      selectedFile:{
        file:null,
        name:'',
      },
      sites:[],
      forms:[],
      error_message:null,
      showDismissibleAlert:false,
      bulkFile:null,
      
    }
  },
  methods : {
    
    formSubmit(e){
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             form:this.form.form,
             selectedfile:this.selectedFile,
             
           },
           api: '/api/import-inspection-units'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                this.$refs.uploadFile.value=null;
                this.selectedFile.file = null;
                this.selectedFile.name = '';
                this.bulkFile = null;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Inspection Unit Imported Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    //this.$router.push({ name: 'periodic-tasks' });
                    this.$refs.uploadFile.value=null;
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    this.bulkFile = null;
                    this.form.form = '';
                    this.form.site = '';
                    window.scrollTo(0,0);
                });
                
            }
        });
    },
    sampleDownload(){
      var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/inspection_unit_sample.xlsx';
      window.open(url, '_blank');
    },
    onFileChange(event) {
            
            this.selectedFile.file = null;
            this.selectedFile.name = '';
            var input = event.target;
            
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.file = input.files[0];
                

                if ((this.form.file.type != 'application/vnd.ms-excel') && this.form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    
                    this.form.file="";
                    this.bulkFile = null;

                    this.showDismissibleAlert=false;
                    Swal.fire({
                      icon: 'error',
                      title: "Please upload .xls or .xlsx files",
                    })
                } else if (this.form.file.size > 2097152) {

                    this.form.file="";
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    this.showDismissibleAlert=false;
                    this.bulkFile = null;

                    Swal.fire({
                      icon: 'error',
                      title: "Maximum 20 MB files are allowed to upload"
                    })
                } else {
                    reader.onload = (e) => {
                        
                        this.selectedFile.file = e.target.result;
                        this.selectedFile.name = this.form.file.name;

                        this.showDismissibleAlert=false;
                    }
                }
            }
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },

    allforms(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-forms'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.forms = data;
                return this.forms;
            }
        });
    },

    updateForm(){
      this.form.form = '';

      this.allforms();
    }
    
    
  },
  mounted(){
    this.allSites();
    //this.allforms();
  }
}
</script>
